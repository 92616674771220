import { normalize } from "polished"
import { createGlobalStyle, css } from "styled-components"

import colors from "../styles/colors"

export const GlobalStyle = createGlobalStyle`

  ${normalize()}

  ${({ includeFontFamily }) =>
    includeFontFamily &&
    css`
      @font-face {
        font-family: "ProximaNova";
        src: url("/fonts/ProximaNova-Light.woff2") format("woff2"),
          url("/fonts/ProximaNova-Light.woff") format("woff"),
          url("/fonts/ProximaNova-Light.otf") format("opentype");
        font-style: normal;
        font-weight: 200;
      }

      @font-face {
        font-family: "ProximaNova";
        src: url("/fonts/ProximaNova-Reg.woff2") format("woff2"),
          url("/fonts/ProximaNova-Reg.woff") format("woff"),
          url("/fonts/ProximaNova-Reg.otf") format("opentype");
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: "ProximaNova";
        src: url("/fonts/ProximaNova-Semibold.woff2") format("woff2"),
          url("/fonts/ProximaNova-Semibold.woff") format("woff"),
          url("/fonts/ProximaNova-Semibold.otf") format("opentype");
        font-style: normal;
        font-weight: 600;
      }

      @font-face {
        font-family: "ProximaNova";
        src: url("/fonts/ProximaNova-Extrabld.woff2") format("woff2"),
          url("/fonts/ProximaNova-Extrabld.woff") format("woff"),
          url("/fonts/ProximaNova-Extrabld.otf") format("opentype");
        font-style: normal;
        font-weight: 700;
      }

      @font-face {
        font-family: "ProximaNova";
        src: url("/fonts/ProximaNova-Black.woff2") format("woff2"),
          url("/fonts/ProximaNova-Black.woff") format("woff"),
          url("/fonts/ProximaNova-Black.otf") format("opentype");
        font-style: normal;
        font-weight: 800;
      }
      @font-face {
        font-family: "RedHatTextMedium";
        src: url("/fonts/RedHatText-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
      }
      @font-face {
        font-family: "RedHatTextRegular";
        src: url("/fonts/RedHatText-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
      }
      @font-face {
        font-family: "KlarheitKurrentRegular";
        src: url("/fonts/KlarheitKurrent-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
      }
    `}

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'ProximaNova';
  }

  .inner-left{
    h1{
      font-family: 'RedHatTextRegular';
    }
  }

  .sb-show-main {
    padding: 20px
  }

  /* HOVER.CSS animation effects */

  /* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.blue};
    height: 4px;
    transform: translateY(4px);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:before, &:focus:before, &:active:before {
    transform: translateY(0);
  }
}

/* Sweep To Top */
.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.blue};
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:focus, &:active {
    color: white;
  }
  &:focus:after, &:active:after {
    transform: scaleY(1);
  }
}

/* Reduction transition for linkedin link */
 .reduceHover {
  transition: transform 0.8s ease;
  &:hover {
    transform: scale(0.9);
  }
 }
`

export default GlobalStyle
