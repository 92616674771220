import { css } from "styled-components"

export const screen_sizes = {
  desktop: 992,
  tablet: 992,
  phone: 576,
  flexWidth: 800,
}

// Iterate through the sizes and create a media template
export const above = Object.keys(screen_sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${screen_sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

const below = Object.keys(screen_sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screen_sizes[label] - 1}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default below
