export default {
  blue: "#59CFF5",
  supportBlue: "#0E264B",
  darkBlue: "#0D2141",
  white: "#FFFFFF",
  lightGrey: "#F7F7F7",
  offWhite: "#FBFBFB",
  mediumGrey: "#D5D5D5",
  supportGrey: "#B3B3B3",
  darkGrey: "#464646",
  offBlack: "#25282B",
  strongBlack: "#0E264B",
  tag: {
    hover: "#E9E9E9",
  },
  button: {
    primary: {
      hover: "#4FC0E5",
      disabled_font: "#0E264B",
      disabled: "#334D73",
    },
    secondary: {},
  },
  input: {
    outline: "#2C4671",
    active: {
      outline: "#FFFFFF",
    },
  },
  stickyLink: "#0D2141",
}
